<template>
  <div>
    <a-layout>
      <!-- <a-layout-header>
        <Header />
      </a-layout-header> -->
      <a-layout-content>
        <div v-if="$route.meta.title != 'Beranda'" class="titlepage">
          
        </div>
        <router-view />
        <!-- <ContentRepeat /> -->
      </a-layout-content>
      <!-- <a-layout-footer>
        <Footer />
      </a-layout-footer> -->
    </a-layout>
  </div>
</template>

<style src="@/assets/css/homepage.css" lang="css"></style>
<style src="@/assets/css/responsivehome.css" lang="css"></style>

<script>
//import Header from "@/components/layouts/header.vue";
//import Footer from "@/components/layouts/footer.vue";
//import ContentRepeat from "@/components/layouts/contentrepeat.vue";
export default {
  components: {
    //Header,
    //Footer,
    //ContentRepeat,
  },
  data() {
    return {};
  },
};
</script>
